import { ShowTutorStageViewProps } from "../../models";
import { Atom, state } from ":mods";
import { For, Match, Show, Switch, createEffect, splitProps } from "solid-js";
import { getSubModuleRoute } from "../../methods";
import { useParams } from "@solidjs/router";
import { getStageListDetails } from "../../apis";

export function ShowTutorStage(props: ShowTutorStageViewProps) {
  const [local, others] = splitProps(props, ["$"]);
  const params = useParams();
  const stage_id = params.id;
  const $details = state.createAsync([getStageListDetails, stage_id]);
  local.$.actions.setLayout({
    title: `course material`,
    sideInfo: [
      {
        title: "Section Info",
        element: () => (
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, qui? Sapiente quis facilis voluptates
            corrupti natus voluptatem fugiat eaque quisquam et, temporibus voluptatum ut dolorum necessitatibus impedit
            amet ipsa totam?
          </span>
        ),
      },
    ],
  });

  createEffect(() => {
    if ($details.state.success) {
      const value = $details.unwrap.value;
      local.$.actions.updateLayout({
        title: `${value.data.name} Material`,
      });
    }
  });

  function getNumber(num: string | number) {
    const numb = Number(num);
    return numb >= 10 ? numb : `0${numb}`;
  }
  return (
    <Switch>
      <Match when={$details.state.loading}>
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Training Tutor Courses"
            msg="Loading course modules..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      </Match>
      <Match when={$details.state.failed}>an error occured while fetching stage details {$details.error}</Match>
      <Match when={$details.state.success}>
        <section class="text-16px mt-28px">
          <span>
            <p>{$details.value?.data?.description}</p>
          </span>
          <section class="flex flex-col space-y-2">
            <Show
              when={$details.value?.data?.modules && $details.value?.data?.modules?.length > 0}
              fallback={"This stage has no material to teach!."}
            >
              <For each={$details.value.data.modules}>
                {(module, index) => {
                  return (
                    <Atom.Foldable.Accordion
                      arrowMode="end"
                      title={
                        <section class="flex flex-row justify-start items-center w-full">
                          <span>Module {getNumber(index() + 1)}</span>
                          {" - "}
                          <span>{module.name}</span>
                        </section>
                      }
                      class="border-2 border-solid border#p px-2 py-1"
                      activeClass="border!highlight"
                    >
                      <div class="flex flex-col mt-2">
                        <Show
                          when={module.submodule && module.submodule.length > 0}
                          fallback={"This module has no material"}
                        >
                          <For each={module.submodule}>
                            {(submodule, index) => {
                              return (
                                <div class="flex flexrow w-full h-fit justify-between">
                                  <span>
                                    Topic {getNumber(index())}: {submodule.name}
                                  </span>
                                  <button
                                    class="cursor-pointer"
                                    onclick={() => {
                                      const route = getSubModuleRoute(submodule.id);
                                      local.$.actions.navigate(route);
                                    }}
                                  >
                                    view
                                  </button>
                                </div>
                              );
                            }}
                          </For>
                        </Show>
                      </div>
                    </Atom.Foldable.Accordion>
                  );
                }}
              </For>
            </Show>
          </section>
        </section>
      </Match>
    </Switch>
  );
}
